<template>
  <div class="how-it-works">
    <h3 class="head">How it works?</h3>
    <div class="body">
      <div
        v-for="(item, index) of items"
        :key="item.id"
        class="how-it-works-item"
      >
        <img
          :src="require(`@/assets/images/easy-steps/${item.path}.svg.png`)"
        />
        <div class="step-section">
          <span class="step-item">{{ index + 1 }}</span>
        </div>
        <p class="title font-weight-bold">{{ item.title }}</p>
        <p class="sub-title font-weight-thin" v-html="item.subTitle"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowItWorks",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.how-it-works {
  padding: 20px;
  background-color: #f9fafb;
  .head {
    text-align: center;
    font-size: 22px;
  }
  .how-it-works-item {
    width: 100%;
    min-width: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 40px;
    .step-section {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--primary-color);
      border-radius: 9999px;
      margin-top: 10px;
      .step-item {
        font-family: "Avenir Next LT W05 Regular";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
    img {
      display: none;
    }
    .title {
      font-size: 17px;
      margin-top: 10px;
    }
    .sub-title {
      font-size: 15px;
      a {
        color: var(--primary-color) !important;
        text-decoration: underline !important;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .how-it-works {
    .body {
      display: flex;
    }
    .how-it-works-item {
      padding: 0 20px;
      img {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .how-it-works-item {
    padding: 0 40px;
  }
}
</style>